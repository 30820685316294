<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>
            Edición Nota de Enfermeria No. {{ id_nota }} ( <span class="small"> {{ fecha }})</span>
            <template v-slot:button>
                <router-link :to="{ name: 'historias.perfil.usuario', params: { idUsuario: usuario.id } }"
                    class="btn btn-success btn-sm shadow-sm mx-2">
                    Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
                </router-link>
            </template>
        </page-header-compact>
        <div class="container-xl px-4">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <span class="font-weight-bolder">Profesional: {{ name_user }}</span>
                    <div class="row form-group mt-2">
                        <div class="col-lg-4 form-group">
                            <label class="small mb-1 font-weight-bolder">Fecha <small
                                    class="text-danger">*</small></label>
                            <input v-model="fecha" class="form-control form-control-sm" type="date" :class="{
            'is-invalid': $v.fecha.$error && $v.fecha.$dirty
        }" />
                        </div>
                        <div class="form-group col-lg-4 col-md-12 col-sm-12">
                            <label class="small mb-1 font-weight-bolder">Hora<small
                                    class="text-danger">*</small></label>
                            <input type="time" class="form-control form-control-sm" v-model="hora"
                                :class="{ 'is-invalid': $v.hora.$error && $v.hora.$dirty }">
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="card-header bg-light text-default ">
                            Examen Fisico
                        </div>
                        <div class="card-body">
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-stethoscope"></i> P.A.SISTOL /
                                    P.A.DIASTOL <span class="text-danger">(mmHg)</span> </label>
                                <div class="col-sm-6 ">
                                    <div class="row">
                                        <div class="col-sm ">
                                            <input type="number" v-model="pa_sistolica" class="form-control"
                                                :class="{ 'is-invalid': $v.pa_sistolica.$error && $v.pa_sistolica.$dirty }">
                                        </div>
                                        <div class="col-sm-1 ">
                                            <h1>/</h1>
                                        </div>
                                        <div class="col-sm ">
                                            <input type="number" v-model="pa_diastolica" class="form-control"
                                                :class="{ 'is-invalid': $v.pa_diastolica.$error && $v.pa_diastolica.$dirty }">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-history"></i> PULSO <span
                                        class="text-danger">(lat/min)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="pulso" class="form-control"
                                        :class="{ 'is-invalid': $v.pulso.$error && $v.pulso.$dirty }">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-thermometer"></i> TEMPERATURA
                                    <span class="text-danger">(ºC)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="temperatura" class="form-control"
                                        :class="{ 'is-invalid': $v.temperatura.$error && $v.temperatura.$dirty }">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-lungs"></i> RESPIRACION <span
                                        class="text-danger">(r/m)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="respiracion" class="form-control"
                                        :class="{ 'is-invalid': $v.respiracion.$error && $v.respiracion.$dirty }">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-weight"></i> PESO <span
                                        class="text-danger">(KG)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="peso" class="form-control"
                                        :class="{ 'is-invalid': $v.peso.$error && $v.peso.$dirty }">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label"><i class="fa fa-ruler-vertical"></i> TALLA <span
                                        class="text-danger">(CM)</span></label>
                                <div class="col-sm-6">
                                    <input type="number" v-model="talla" class="form-control"
                                        :class="{ 'is-invalid': $v.talla.$error && $v.talla.$dirty }">
                                </div>
                            </div>
                        </div>
                        <div class="card-header bg-light text-default ">
                            Revisión por sistemas
                        </div>
                        <div class="card-body">
                            <div class="form-group row" v-for="item in partes_revision" :key="item.id">
                                <label class="col-sm-6 col-form-label">
                                    <li>{{ item.nombre }}</li>
                                </label>
                                <div class="col-sm-6 p-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :id="item.id" value="Normal"
                                            v-model="item.respuesta" />
                                        <label class="form-check-label">Normal</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :id="item.id" value="Anormal"
                                            v-model="item.respuesta" />
                                        <label class="form-check-label">Anormal</label>
                                    </div>
                                </div>
                            </div>
                            <span class="text-danger d-block"
                                v-if="$v.partes_revision.$error && $v.partes_revision.$dirty">Debe
                                completar la revision por sistemas</span>
                        </div>
                        <div>
                            <label class="col-sm-6 col-form-label"> Nota enfermeria </label>
                            <div class="col-sm-12">
                                <textarea cols="30" rows="10" class="form-control" v-model="descripcion"
                                    :class="{ 'is-invalid': $v.descripcion.$error && $v.descripcion.$dirty }"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3 d-flex justify-content-end">
                        <button class="btn btn-success btn-sm" @click="save()"><i class="fa fa-sync-alt fa-fw"></i>Actualizar Nota</button>
                    </div>
                </div>
            </div>
        </div>
        

    </main>
</template>
<script>
import notasEnfermeriaService from "../../../../services/notasEnfermeriaService";
import Toast from '../../../../components/common/utilities/toast';
import { required } from "vuelidate/lib/validators";
import partesRevisionSistemasService from "../../../../services/partesRevisionSistemasService";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import usuarioService from "../../../../services/usuarioService";
import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario"
export default {
    components: { PageHeaderCompact, modalInformacionUsuario, cardInformacionUsuario },
    data() {
        return {
            peso: '',
            talla: '',
            pa_sistolica: '',
            pa_diastolica: '',
            respiracion: '',
            pulso: '',
            temperatura: '',
            descripcion: '',
            partes_revision: [],
            revision: {
                respuesta: ''
            },
            id_usuario: '',
            fecha: '',
            hora: '',
            usuario: {},
            name_user:'',
            id_nota:''
        }
    },
    validations() {
        return {
            peso: { required },
            talla: { required },
            pa_sistolica: { required },
            pa_diastolica: { required },
            respiracion: { required },
            pulso: { required },
            temperatura: { required },
            descripcion: { required },
            fecha: { required },
            hora: { required },
            partes_revision: {
                $each: {
                    respuesta: { required }
                }
            }
        }
    },
    methods: {
        async cargarPartesRevision() {
            const response = await partesRevisionSistemasService.index({
                sexo: this.usuario.sexo
            });
            this.partes_revision = response.data.map(x => {
                return {
                    ...x,
                    respuesta: 'Normal'
                }
            });
        },
        async cargarUsuario() {
            const response = await usuarioService.show(this.id_usuario);
            this.usuario = response.data;
        },
        async showNota(id) {
            const response = await notasEnfermeriaService.show(id);
            this.id_usuario = response.data.id_usuario;
            this.name_user = response.data.user.name;
            this.id_nota = response.data.id;
            this.peso = response.data.peso;
            this.talla = response.data.talla;
            this.pa_sistolica = response.data.pa_sistolica;
            this.pa_diastolica = response.data.pa_diastolica;
            this.respiracion = response.data.respiracion;
            this.pulso = response.data.pulso;
            this.temperatura = response.data.temperatura;
            this.descripcion = response.data.descripcion;
            this.fecha = response.data.fecha;
            this.hora=response.data.hora;
            this.partes_revision = response.data.notas_enfermeria_sistemas.map(x => {
                return {
                    id: x.sistema.id,
                    nombre: x.sistema.nombre,
                    respuesta: x.estado
                }
            });

        },
        async save() {
            try {

                this.$v.$touch();

                if (this.$v.$invalid) return;

                let sistemas = this.partes_revision.map(x => {
                    return {
                        id: x.id,
                        nombre: x.nombre,
                        respuesta: x.respuesta,
                    }
                });

                let notas = {
                    id_nota:this.id_nota,
                    id_usuario: this.id_usuario,
                    peso: this.peso,
                    talla: this.talla,
                    pa_sistolica: this.pa_sistolica,
                    pa_diastolica: this.pa_diastolica,
                    respiracion: this.respiracion,
                    pulso: this.pulso,
                    temperatura: this.temperatura,
                    descripcion: this.descripcion,
                    fecha: this.fecha,
                    hora: this.hora,
                    revision_sistemas: sistemas,
                }


                this.LoaderSpinnerShow();
                await notasEnfermeriaService.update(notas);
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos() {
            this.descripcion = '';
        },
    },
    async created() {
        try {
            this.LoaderSpinnerShow();
            await this.showNota(this.$route.params.id);
            await this.cargarUsuario();
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
    }
}
</script>
